/* =Home Layout
----------------------------------------------------------*/

.panel-twocol-press {
  position: relative;
}

// This overrides a style coming from Radix Layouts. Our gutter is a little bigger.
.container .container {
  margin-left: -20px;
  margin-right: -20px;
}

.doormat-section {
  margin: 40px 0;
}
